import React from 'react'
import { Link } from 'gatsby'

const Logo = props => (
  <div className="site-logo">
    <Link to="/">
      <img src={'/logo-pensamiento-visual.jpg'} alt={props.title} />
    </Link>
  </div>
)

export default Logo
